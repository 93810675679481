<template>
  <div>
    <div class="bg_wra">新闻中心</div>
    <div class="news_con">
      <a-tabs :default-active-key="dictItemId" @change="callback">
        <a-tab-pane :key="item.dictItemId" v-for="item in typeList" :tab="item.name"></a-tab-pane>
        <!--<a-tab-pane key="1" tab="新闻动态" force-render></a-tab-pane>
        <a-tab-pane key="2" tab="行业观察" force-render></a-tab-pane>
        <a-tab-pane key="3" tab="党建动态" force-render></a-tab-pane>-->
      </a-tabs>
      <ul>
        <li v-for="item in list" :key="item.id" @click="seeDetails(item)">
          <a-space :size="30">
            <img :src="item.img" alt="">
            <div class="news_text">
              <div class="title">{{item.title}}</div>
              <div class="desc">{{item.intro}}</div>
              <div class="time">{{item.time}}</div>
            </div>
          </a-space>
        </li>
      </ul>
      <a-pagination v-model="pageNumber" :total="total" show-less-items class="pgn" @change="onChange"/>
    </div>
  </div>
</template>

<script>
// import {getNewsType} from "../api";
import $ from 'jquery'
// import {getNews} from "../api";

export default {
  name: "NewsCenter",
  data(){
    return{
      detailId:'',
      dictItemId:'',
      typeList:[],
      list:[],
      total:1,
      pageNumber:1,
      pageSize:10,
    }
  },
  methods:{
    /*async getNewsType01(){
      const params={
        pageNumber:1,
        pageSize:30,
        dId:'ed59c7525d534eada0b8734211fb51ca',
        name:''
      }
      const res=await getNewsType(params)
      this.typeList = res.data.list;
      /!*this.total = res.data.totalCount;*!/
      console.log(res,"88888888888888")
    },*/
    getListType(){
        var _typeList = [];
        $.ajax({
          type: "post",
          url: "http://ccsc.9zhinet.com/dict/dictItemPageData",
          dataType: "json",
          async:false,
          data:{
            pageNumber:1,
            pageSize:30,
            dId:'ed59c7525d534eada0b8734211fb51ca',
            name:''
          },
          success:function(res) {
            _typeList = res.list;
          }
        })
        return _typeList;
      },
      getList(dictItemId){
          var obj = {};
          var _list = [];
          var _total = 0;
          $.ajax({
              type: "post",
            // url: "http://10.255.255.33:8780/yzNews/selectByHt",
              url: "http://ccsc.9zhinet.com/yzNews/selectByHt",
              dataType: "json",
              async:false,
              data:{
                  pageNumber:this.pageNumber,
                  pageSize:this.pageSize,
                  dictItemId:dictItemId
              },
              success:function(res) {
                  _list = res.list;
                  obj._list = _list;
                  _total = res.totalCount;
                  obj._total = _total

              }
          })
          return obj;
      },
      getInfoDetail(id){
          $.ajax({
              type: "get",
            // url: "http://10.255.255.33:8780/yzNews/findById?id=" + id,
              url: "http://ccsc.9zhinet.com/yzNews/findById?id=" + id,
              dataType: "json",
              async:false,
              data:{
                  pageNumber:1,
                  pageSize:10,
                  dId:'ed59c7525d534eada0b8734211fb51ca',
                  name:''
              },
              success:function(res) {
                  var reg= /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
                  var url =  res.data.content.match(reg);
                  window.open(url)
              }
          })
      },
    /*async getList(){
      const params={
        pageNumber:this.pageNumber,
        pageSize:this.pageSize,
        dictItemId:this.dictItemId
      }
      const res=await getNews(params)
      this.list = res.data.list;
      this.total = res.data.totalCount;
      console.log(res.data.list,"222")
    },*/
    callback(key) {
      this.dictItemId=key
      this.pageNumber=1
      // this.getList(this.dictItemId)
      this.list = this.getList(this.dictItemId)._list;
      this.total = this.getList(this.dictItemId)._total;
    },
    seeDetails(item){
        //type = 1,是外链地址
      if (item.type == 1) {
          this.detailId = item.id;
          this.getInfoDetail(this.detailId)
      } else {
        this.$router.push({name: 'details', params: {id: item.id}})
      }
    },
    onChange(cur){
      this.pageNumber=cur
      // this.getList(this.dictItemId)
      this.list = this.getList(this.dictItemId)._list;
      this.total = this.getList(this.dictItemId)._total;
    }
  },
  created() {
      this.typeList =  this.getListType();
      this.dictItemId = this.typeList[0].dictItemId;
      this.list = this.getList(this.dictItemId)._list;
      this.total = this.getList(this.dictItemId)._total;
  }
}
</script>

<style lang="less" scoped>
.bg_wra {
  height: 480px;
  line-height: 480px;
  text-align: center;
  font-size: 40px;
  color: #fff;
  background: url("../assets/image/bg_news.png") no-repeat center;
  background-size: cover;
}
.news_con{
  width: 1200px;
  margin: 0 auto;
  ul{
    padding: 0;
    li{
      list-style: none;
      margin: 30px 0;
      cursor: pointer;
      img{
        width: 250px;
        height: 140px;
      }
      .title{
        font-size: 18px;
        color: #333;
      }
      .desc{
        color: #999;
        margin: 10px 0;
      }
      .time{
        color: #999;
      }
      &:hover .title{
        color: #0167FF;
      }
    }
  }
  .pgn{
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px){
  .bg_wra{
    height: 300px;
    line-height: 300px;
    background-position: top;
  }
  .news_con{
    width: 100vw;
    ul{
      li{
        padding-right: 30px;
        img{
          display: none;
        }
      }
    }
    .pgn{
      display: none;
    }
  }
}
</style>
